<script lang="ts" setup>
import { TuiDivider, TuiModal, TuiModalTitle } from '@clickbar/tailwindui-vue'
import { usePage } from '@inertiajs/vue3'
import { startInactiveSpan, type Span } from '@sentry/vue'
import { useStorage } from '@vueuse/core'
import axios from 'axios'
import { marked } from 'marked'
import { computed, ref, watch } from 'vue'

import ReleaseNotesPager from '@/Components/ReleaseNotes/ReleaseNotesPager.vue'

const shownRelease = computed(() => releases.value[shownReleaseIndex.value])
</script>

<script lang="ts">
export interface Release {
  name: string
  content: string
}

marked.use({
  useNewRenderer: true,
  renderer: {
    heading(token) {
      const level = token.depth
      const id = token.text.toLowerCase().replaceAll(/[^\da-z]+/g, '-')

      return `<h${level} id="${id}">${token.text}</h${level}>`
    },
    link(token) {
      const isLocalLink =
        token.href.startsWith(`${location.protocol}//${location.hostname}`) ||
        token.href.startsWith('#') ||
        token.href.startsWith('/')

      return isLocalLink
        ? `<a href="${token.href}">${token.text}</a>`
        : `<a href="${token.href}" target="_blank">${token.text}</a>`
    },
  },
})

export const releases = ref<Release[]>([])
export const latestRelease = computed(() => releases.value[0])

const releaseNotesShown = ref(false)
let span: Span | undefined = undefined

watch(releaseNotesShown, (shown) => {
  if (span !== undefined) {
    span.end()
    span = undefined
  }

  if (!shown) return

  span = startInactiveSpan({
    name: 'view_release_notes',
    op: 'view',
    attributes: {
      shown,
    },
  })
})

const shownReleaseIndex = ref(0)
const hasNewVersion = ref(false)

export function showReleaseNotes(newVersion = false) {
  releaseNotesShown.value = true
  shownReleaseIndex.value = 0
  hasNewVersion.value = newVersion
}

const page = usePage()
const releaseParserRegex = /(?:^|\n)# (.+?)\n((?:.|\n)+?)(?=\n# |$)/g // Parses out all releases and captures name + body

const lastSeenVersion = useStorage('lastSeenVersion', null as string | null)

watch(
  () => !!page.props?.auth.user,
  async (loggedIn) => {
    if (!loggedIn) return

    const response = await axios.get('/release-notes')
    const markdown = response.data as string

    const matches = [...markdown.matchAll(releaseParserRegex)]
    for (const match of matches) {
      releases.value.push({
        name: match[1],
        content: match[0].trim(),
      })
    }

    if (latestRelease.value && latestRelease.value.name !== lastSeenVersion.value) {
      lastSeenVersion.value = latestRelease.value.name
      showReleaseNotes(true)
    }
  },
  { immediate: true },
)
</script>

<template>
  <TuiModal v-model:show="releaseNotesShown" closeable max-width="3xl" no-autofocus>
    <div class="w-full">
      <TuiModalTitle>Versionshinweise</TuiModalTitle>

      <TuiDivider class="my-2" />

      <template v-if="shownRelease">
        <ReleaseNotesPager
          v-model:selectedIndex="shownReleaseIndex"
          :releases="releases"
          class="my-3"
        />

        <div v-if="hasNewVersion">
          <p
            class="mb-1 inline rounded bg-primary-500 px-2.5 py-0.5 text-center text-xs font-bold uppercase"
          >
            Neu
          </p>
        </div>

        <article
          class="prose prose-sm max-w-none dark:prose-zinc dark:prose-invert prose-img:rounded-md prose-img:bg-gray-100 prose-img:shadow dark:prose-img:bg-zinc-850"
          v-html="marked(shownRelease.content)"
        />
      </template>
    </div>
  </TuiModal>
</template>
